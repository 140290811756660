import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import Layout from './layout';
import {
  Avatar,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  CardMedia,
  CardActions,
  Link
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import WebIcon from '@mui/icons-material/Web';
import VisibilityIcon from '@mui/icons-material/Visibility';

const defaultProfilePicture = "https://via.placeholder.com/150"; // Placeholder for profile picture

const InvestorDetailPage = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [sectorName, setSectorName] = useState('');
  const [similarProfiles, setSimilarProfiles] = useState([]);
  const [displayedProfiles, setDisplayedProfiles] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchUser = async () => {
    try {
      const userResponse = await axios.get(`https://api.ghsdginnovations.org/api/users/${id}`);
      setUser(userResponse.data);

      // Fetch sector name
      const sectorResponse = await axios.get(`https://api.ghsdginnovations.org/api/sectors/${userResponse.data.sectorId}`);
      setSectorName(sectorResponse.data.name);

      // Fetch similar profiles
      const similarResponse = await axios.get(`https://api.ghsdginnovations.org/api/users/similar/${id}`);
      setSimilarProfiles(similarResponse.data);
      setDisplayedProfiles(similarResponse.data.slice(0, 6)); // Display first 6 profiles initially
      setHasMore(similarResponse.data.length > 6); // Check if there are more profiles to load
    } catch (error) {
      console.error('Error fetching user or similar profiles:', error);
    }
  };

  const loadMoreProfiles = useCallback(() => {
    if (hasMore) {
      const newPage = page + 1;
      const newProfiles = similarProfiles.slice(0, newPage * 6);
      setDisplayedProfiles(newProfiles);
      setPage(newPage);
      setHasMore(newProfiles.length < similarProfiles.length);
    }
  }, [page, similarProfiles, hasMore]);

  useEffect(() => {
    fetchUser();
  }, [id]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMoreProfiles();
        }
      },
      { threshold: 1.0 }
    );

    const target = document.querySelector('#load-more-trigger');
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [loadMoreProfiles]);

  if (!user) {
    return (
      <Layout>
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid container spacing={3} style={{ marginTop: '150px',  marginBottom: '100px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card
            elevation={0}
              sx={{
                border: '1px solid #ddd',
                borderRadius: 2,
              
                
              }}
            >
              
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar
                      src={user.profilePicture || defaultProfilePicture}
                      alt="Profile Image"
                      sx={{ width: 100, height: 100 }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{user.fullName}</Typography>
                    <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                      @{user.role}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
            elevation={0}
              sx={{
                marginTop: '20px',
                border: '1px solid #ddd',
                borderRadius: 2,
                boxShadow: 1,
                '&:hover': {
                  boxShadow: 3,
                },
              }}
            >
              <CardContent>
                <Typography variant="h6">Area(s) of Investment</Typography>
                <Typography variant="body1">{user.bio || "No areas of investment available"}</Typography>
              </CardContent>
            </Card>
           
            <Card
            elevation={0}
              sx={{
                marginTop: '20px',
                border: '1px solid #ddd',
                borderRadius: 2,
                boxShadow: 1,
                '&:hover': {
                  boxShadow: 3,
                },
              }}
            >
              <CardContent>
                <Typography variant="h6">Company Details</Typography>
                <Box display="flex" alignItems="center" mb={1}>
                  <BusinessIcon sx={{ marginRight: '8px' }} />
                  <Typography variant="body1">{user.businessName || "No business details available"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <WorkIcon sx={{ marginRight: '8px' }} />
                  <Typography variant="body1">{sectorName || "No sector details available"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <WebIcon sx={{ marginRight: '8px' }} />
                  <Typography variant="body1">{user.web || "No website details available"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                      <LocationCityIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.city}</Typography>
                    </Box>
                <hr />
                <p>Business Description</p>
                <hr />
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography variant="body1">{user.elevatorPitch || "No business description available"}</Typography>
                </Box>
              </CardContent>
            </Card>
            
          </Grid>
          <Grid item xs={12} md={5}>
            <Card
              sx={{
                padding: 2,
                border: '1px solid #ddd',
                borderRadius: 2,
                boxShadow: 1,
                '&:hover': {
                  boxShadow: 3,
                },
              }}
            >
           <CardContent>
  <Typography variant="h6" component="div" gutterBottom>
    Similar Profiles
  </Typography>
  {displayedProfiles.length > 0 ? (
    <Grid container direction="column" spacing={2}>
      {displayedProfiles.map(profile => (
        <Grid item key={profile._id} xs={12}>
          <Card
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
              alignItems: 'center',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)', // Scales the card up on hover
                boxShadow: 3, // Optional: add a shadow effect on hover
              },
              border: '1px solid #ddd',
              borderRadius: 2,
            }}
          >
            <CardMedia
  component={() =>
    profile.profilePicture ? (
      <img
        src={profile.profilePicture}
        alt={profile.fullName}
        style={{
          width: 60,
          height: 60,
          borderRadius: '50%',
          margin: 8,
          objectFit: 'cover',
        }}
      />
    ) : (
      <Avatar
        sx={{
          width: 60,
          height: 60,
          bgcolor: 'gray',
          fontSize: 24,
          fontWeight: 'bold',
        }}
      >
        {profile.fullName ? profile.fullName.charAt(0).toUpperCase() : '?'}
      </Avatar>
    )
  }
/>

            <CardContent sx={{ flex: '1 0 auto', textAlign: { xs: 'center', sm: 'left' } }}>
              <Typography variant="body1" component="div">
                {profile.fullName}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
  <Link
    component={RouterLink}
    to={`/innovator/${profile._id}`}
    underline="none"
    sx={{ display: 'flex', alignItems: 'center' }} // Align text and icon
  >
    <VisibilityIcon sx={{ mr: 0.5 }} /> 
  
  </Link>
</CardActions>
          </Card>
        </Grid>
      ))}

      {hasMore && <div id="load-more-trigger" style={{ height: '1px' }} />}
    </Grid>
  ) : (
    <Typography variant="body1" color="text.secondary">
      No similar profiles available
    </Typography>
  )}
</CardContent>

            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default InvestorDetailPage;
